
import {Component, Vue, Prop,} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import TitleManager from "@/mixins/TitleManager.vue";
import { StoreAction } from '@/types';
import {Action} from 'vuex-class';
import {capitalize} from '@/helpers/commons'

@Component({
    name: "Dashboard"
})
export default class Dashboard extends mixins(TitleManager) {

    pageTitle = <string>this.$t('page.supervisor.dashboard.title');
    loading = false;
    deployment: any = [];
    deploymentCompleted:number = 0;
    followup: number = 0;
    capitalize = capitalize;


    apiUrl:string = <string>process.env.VUE_APP_API_URL?.replace('/1.0', '') + "/";

    @Action('actions/loadDeployment') loadDeployment!: StoreAction;



    async init(){
        this.loading = true;
        try {
            let result:any;
            result = await this.loadDeployment();

            this.deployment =  Object.keys(result)
                   .filter((key) => Number.isInteger(parseInt(key)))
                   .map((key) => result[key]);
            this.deploymentCompleted = result.statistics.deploymentsFilled;
            this.followup = result.statistics.followupFilled;


        } catch (error) {
            console.log(error);
        }
    }
    isDone(deployment:any){
        return deployment?.done
    }
    isDeployment(deployment:any){
         return deployment?.phases
    }

    beforeMount() {
        this.init()
    }
}
